import { Outlet } from 'react-router-dom';
import { HubsRoutes } from '@maple-tech/company-settings-web-app/Hub';
import { NotificationSettingsRoutes } from '@maple-tech/company-settings-web-app/NotificationSettings';
import { CountriesRoutes } from '@maple-tech/company-settings-web-app/Countries';
import { DepartmentsRoutes } from '@maple-tech/company-settings-web-app/Departments';
import { RegionsRoutes } from '@maple-tech/company-settings-web-app/Regions';
import { DistrictsRoutes } from '@maple-tech/company-settings-web-app/Districts';
import { ClustersRoutes } from '@maple-tech/company-settings-web-app/Cluster';
import { LocationsRoutes } from '@maple-tech/company-settings-web-app/Locations';
import { BuildingsRoutes } from '@maple-tech/company-settings-web-app/Buildings';
import { UsersRoutes } from '@maple-tech/company-settings-web-app/Users';
import { SettingsOutlined } from '@mui/icons-material';
// HubsRoutes,
import { lazy } from 'react';
import Page from '../../core/components/page';
const CreateCoachingCompetencePage = lazy(
  () => import('./CompanySettings/Coaching/CreateCompetenceItems'),
);

import { COMPANIES } from 'Core/API';

export const newCompanySettingsRoutes = {
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.CENTRAL_RETAIL,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.WORKMAPLE,
  ],
  path: 'company-settings',
  icon: <SettingsOutlined />,
  title: 'retail:navigation.companySettings.home',
  permission: 'ADMIN',
  section: true,
  order: 99,
  children: [
    // {
    //   menuItem: true,
    //   requiredRoles: ['admin',COMPANIES.CENTRAL_RETAIL,
    // COMPANIES.VINEYARD_VALLEY,COMPANIES.WORKMAPLE],
    //   path: 'create-company',
    //   title: 'retail:navigation.companySettings.company',
    //   permission: 'MAPLE_ADMIN',
    //   element: <CreateCompanyPage />,
    // },
    // {
    //   menuItem: true,
    //   requiredRoles: ['admin',COMPANIES.CENTRAL_RETAIL,
    // COMPANIES.VINEYARD_VALLEY,COMPANIES.WORKMAPLE],
    //   path: 'permissions',
    //   title: 'retail:navigation.companySettings.permission',
    //   element: <PermissionsPage />,
    // },
    // {
    //   menuItem: true,
    //   requiredRoles: [ 'admin' ],
    //   path: 'coaching/competence-items',
    //   title: 'retail:navigation.companySettings.coaching.competenceItems',
    //   element: <CreateCoachingCompetencePage />,
    // },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'hubs',
      title: 'retail:navigation.companySettings.hubs',
      userAccessLevel: [7], // 7: global manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: HubsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'clusters',
      title: 'retail:navigation.companySettings.clusters',
      userAccessLevel: [7, 6, 5], // 7: global manager, 6: hub manager, 5: country manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: ClustersRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'countries',
      title: 'retail:navigation.companySettings.country',
      userAccessLevel: [7, 6], // global manager and hub manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: CountriesRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'regions',
      title: 'retail:navigation.companySettings.region',
      userAccessLevel: [7, 6, 5], // 7: global manager, 6: hub manager, 5: country manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: RegionsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'districts',
      title: 'retail:navigation.companySettings.district',
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: DistrictsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'locations',
      title: 'retail:navigation.companySettings.location',
      userAccessLevel: [7, 6, 5, 4], // 7: global manager, 6: hub manager, 5: country manager, 4: region manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: LocationsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'stores',
      title: 'retail:navigation.companySettings.store',
      userAccessLevel: [7, 6, 5, 4, 3], // 7: global manager, 6: hub manager, 5: country manager, 4: region manager, 3: district manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: BuildingsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'departments',
      title: 'retail:navigation.companySettings.department',
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: DepartmentsRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.CENTRAL_RETAIL,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.WORKMAPLE,
      ],
      path: 'users',
      title: 'retail:settings.users.section-title',
      userAccessLevel: [7, 6, 5, 4, 3], // 7: global manager, 6: hub manager, 5: country manager, 4: region manager, 3: district manager
      element: (
        <Page>
          <Outlet />
        </Page>
      ),
      children: UsersRoutes.children.map((route) => ({
        ...route,
        requiredRoles: [
          'admin',
          COMPANIES.CENTRAL_RETAIL,
          COMPANIES.VINEYARD_VALLEY,
          COMPANIES.WORKMAPLE,
        ],
      })),
    },
    // {
    //   path: "managed-employee",
    //   title: "retail:navigation.companySettings.managedEmployee",
    //   element: lazy(() =>
    //     import("./CompanySettings/CreateManagedEmployee")
    //   ),
    // },
    // {
    //   menuItem: true,
    //   requiredRoles: ['admin',COMPANIES.CENTRAL_RETAIL,
    // COMPANIES.VINEYARD_VALLEY,COMPANIES.WORKMAPLE],
    //   path: 'portal',
    //   title: 'Portal Ayarları',
    //   element: <PortalSettingsPage />,
    // },
  ],
};
