// import { useUserController } from '@maple-tech/auth/userState';
// import
//   {
//     AutoAwesome
//   } from '@mui/icons-material';
// import
//   {
//     Fab,
//     Popover
//   } from '@mui/material';
// import OpenAI from 'openai';
// import { useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { ChatWrapper } from './ChatWrapper';
// import { systemPrompt } from './systemPrompt';

// const is_dev = false;
// const AI_LOCAL_PATH = 'http://localhost:5050/v1/';
// const AI_REMOTE_PATH =
//   'https://apc1x5idbfla1zh8.us-east-1.aws.endpoints.huggingface.cloud/v1/';
// const LOCAL_MODEL_NAME = 'gemma2:latest';
// const REMOTE_MODEL_NAME = 'tgi';
// const API_PATH = is_dev ? AI_LOCAL_PATH : AI_REMOTE_PATH;
// const MODEL_NAME = is_dev ? LOCAL_MODEL_NAME : REMOTE_MODEL_NAME;
// const openai = new OpenAI({
//   baseURL: API_PATH,
//   apiKey: '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',
//   dangerouslyAllowBrowser: true,
//   organization: 'Maple Retail',
//   project: 'Maple AI',

//   // model: "tgi",
//   // stream: true,
// });

// const MapleAIAsistant = ({ disablePadding, mode = 'page' }) => {
//   const [input, setInput] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [isWriting, setIsWriting] = useState(false);
//   const writerRef = useRef('');
//   const userState = useUserController((f) => f.user);

//   const customStyle =
//     mode === 'page'
//       ? {}
//       : {
//           maxWidth: 600,
//           height: 800,
//         };

//   useEffect(() => {
//     const history = localStorage.getItem('mapleAIHistory');
//     if (history) {
//       setMessages(JSON.parse(history));
//     }
//     systemPrompt.content += `\nThe name of the person you're talking to is ${userState?.name} and the country manager. Communicate accordingly.`;

//     // Sistem prompt'unu gönder
//     const sendSystemPrompt = async () => {
//       try {
//         const response = await openai.chat.completions.create({
//           model: MODEL_NAME,
//           messages: [systemPrompt],
//           max_tokens: 32768,
//           frequency_penalty: 0.0,
//           temperature: 0.95,
//           stream: true,
//         });

//         let resp = '';
//         for await (const chunk of response) {
//           resp += chunk.choices?.delta?.content || '';
//         }

//         if (resp) {
//           setMessages((prev) => [
//             ...prev,
//             {
//               content: resp,
//               role: 'system',
//             },
//           ]);
//         }
//       } catch (error) {
//         console.error('API isteği başarısız oldu:', error);
//       }
//     };

//     sendSystemPrompt();
//   },[]);

//   useEffect(() => {
//     if (messages.length === 0) return;
//     localStorage.setItem('mapleAIHistory', JSON.stringify(messages));
//   }, [messages]);

//   const handleSend = async () => {
//     if (!input.trim()) return;

//     setMessages((prev) => [
//       ...prev,
//       {
//         content: input,
//         role: 'user',
//         time: new Date().toLocaleTimeString(),
//       },
//       {
//         content: '',
//         role: 'asistant',
//         isWriting: true,
//         time: new Date().toLocaleTimeString(),
//       },
//     ]);
//     setInput('');

//     try {
//       setIsWriting(true);
//       let previouseMessage = messages.map((f) => ({
//         role: f.role,
//         content: f.content,
//       }));
//       const stream = await openai.chat.completions.create({
//         model: MODEL_NAME,
//         messages: [
//           systemPrompt,
//           ...previouseMessage,
//           {
//             role: 'user',
//             content: input,
//           },
//         ],
//         max_tokens: 8192,
//         stream: true,
//       });

//       let tmpMessages = '';
//       for await (const chunk of stream) {
//         if (!chunk.choices?.delta?.content) continue;
//         tmpMessages += chunk.choices?.delta?.content;
//         writerRef.current = tmpMessages;
//         // setCount((f) => f + 1); // You might not need this count state
//       }

//       setMessages((prev) => {
//         const lastUserMessageIndex = prev
//           .map((msg, index) => (msg.role === 'asistant' ? index : null))
//           .filter((index) => index !== null)
//           .pop();
//         if (lastUserMessageIndex !== undefined) {
//           prev[lastUserMessageIndex].isWriting = false;
//           prev[lastUserMessageIndex].content = tmpMessages;
//         }
//         return [...prev];
//       });

//       writerRef.current = '';
//       setIsWriting(false);
//     } catch (error) {
//       setIsWriting(false);
//       console.error('API isteği başarısız oldu:', error);
//     }
//   };

//   return (
//     <ChatWrapper
//       messages={messages}
//       writerRef={writerRef}
//       disablePadding={disablePadding}
//       mode={mode}
//       customStyle={customStyle}
//       input={input}
//       setInput={setInput}
//       handleSend={handleSend}
//       isWriting={isWriting}
//     />
//   );
// };

// export const MapleAIAsistantFab = () => {
//   const [anchorEl, setAnchorEl] = useState(null); // Modal açık/kapalı durumu
//   const location = useLocation();
//   const handleOpen = (e) => setAnchorEl(e.currentTarget);
//   const handleClose = () => setAnchorEl(null);
//   const open = Boolean(anchorEl);
//   console.log(
//     "localStorage.getItem('mapleAiIsEnable')",
//     localStorage.getItem('mapleAiIsEnable'),
//   );
//   return;

//   if (localStorage.getItem('mapleAiIsEnable') !== 'true') return null;
//   // eğer urlde maple-ai yazıyorsa render alma
//   if (location.pathname.includes('maple-ai')) return null;
//   return (
//     <>
//       {/* FAB Butonu */}
//       <Fab
//         color="primary"
//         aria-label="chat"
//         sx={{ position: 'fixed', bottom: 16, right: 16 }}
//         onClick={handleOpen}
//       >
//         <AutoAwesome />{' '}
//         <AutoAwesome
//           sx={{
//             position: 'absolute',
//             top: 12,
//             right: 12,
//             width: 12,
//             height: 12,
//             zIndex: 1000,
//           }}
//         />
//       </Fab>

//       {/* Modal (Popup) */}
//       <Popover
//         open={open}
//         onClose={handleClose}
//         anchorEl={anchorEl}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <MapleAIAsistant disablePadding mode="fab" />
//       </Popover>
//     </>
//   );
// };
// export default MapleAIAsistant;

import ReactMarkdown from 'react-markdown';
import React, { useLayoutEffect, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  Modal,
  Fab,
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  TextareaAutosize,
  Popover,
  useTheme,
  Container,
  ListItemAvatar,
  Avatar,
  Stack,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import {
  AspectRatio,
  AutoAwesome,
  Camera,
  CameraEnhanceRounded,
  ClearAll,
  ClearOutlined,
  MicRounded,
  UploadFileRounded,
  ZoomInMap,
  ZoomOutMap,
} from '@mui/icons-material';
import OpenAI from 'openai';
import { useLocation } from 'react-router-dom';
import CircleProggressBar from '@mui/material/CircularProgress';
import { documentPath } from '../../../retail/routes/order/helpers';
import { useUserController } from '@maple-tech/auth/userState';
import { CopyOutlined } from '@ant-design/icons';
import remarkGfm from 'remark-gfm';

const is_dev = false;
const AI_LOCAL_PATH = 'http://localhost:5050/v1/';
const AI_REMOTE_PATH =
  'https://apc1x5idbfla1zh8.us-east-1.aws.endpoints.huggingface.cloud/v1/';
const LOCAL_MODEL_NAME = 'gemma2:latest';
const REMOTE_MODEL_NAME = 'tgi';
const API_PATH = is_dev ? AI_LOCAL_PATH : AI_REMOTE_PATH;
const MODEL_NAME = is_dev ? LOCAL_MODEL_NAME : REMOTE_MODEL_NAME;
const openai = new OpenAI({
  baseURL: API_PATH,
  apiKey: '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',
  dangerouslyAllowBrowser: true,
  organization: 'Maple Retail',
  project: 'Maple AI',

  // model: "tgi",
  // stream: true,
});

const systemPrompt = {
  role: 'system',
  content: `### **Revised AI Prompt for Retail Management Assistant**

#### **Persona: Manager Assistant AI**

**Current Date: 2025-02-07**

#### **Role:** Provide actionable insights to retail managers using data from the **Maple application**. Facilitate data-driven decision-making while ensuring responses are aligned with the **user’s permissions and Maple’s scope**.

#### **Communication Style:**

* **Business professional,** maintaining kindness, clarity, and consistency.

#### **Response Format:**

* **Concise executive summaries** (unless detailed explanations are requested).  
* **Use bullet points** for lists.  
* **Use tables** for comparisons.  
* **Use charts** for visual insights (if supported by the medium).  
* Make sure you only use letters from the alphabet of supported languages

---

### **Behavioral Rules:**

* Maintain **professionalism** in all responses.  
* Avoid generating **inappropriate or harmful content**.  
* Politely **ask for clarification** if user intent is unclear.  
* **Never guess an answer.** If uncertain or if the requested information is unavailable, state:  
  * *"I cannot provide an accurate answer to that. Please consult the appropriate source or department."*

---

### **Language Rules:**

* Respond in the **same language the user used** while ensuring professionalism and clarity.  
* **Supported languages:** Turkish (TR), English (EN), French (FR), German (DE), Dutch (NL).  
* If a user inputs a language outside this list, **default to English**.  
* Make sure you only use letters from the alphabet of supported languages

---

### **Maple-Specific Boundaries:**

* **Exclusive to Maple:**  
  * You **only serve Maple users** and provide insights based on **Maple's application data**.  
  * If a question is **unrelated to Maple**, politely clarify:  
    * *"I am here to assist with Maple-related inquiries only."*  
* **Knowledge and Data Boundaries:**  
  * Use **only** the data accessible within the **Maple application** or other **approved data sources**.  
  * If external information is required, **state that it is beyond scope**.  
* **User Permission Enforcement:**  
  * **Store Managers**: Can access **only their assigned stores** (writer/reader permissions).  
  * **District Managers**: Can access **only their assigned districts**.  
  * If a request exceeds the user’s scope, politely decline:  
    * *"I cannot provide details for other stores/districts due to permission restrictions."*

---

### **Data Sources (Maple Modules):**

* **Checklist**: Operational data and store performance assessment.  
* **Knowledge Base**: Documentation, guides, and resources.  
* **Survey**: Feedback and performance metrics.  
* **Ticket**: Issue tracking and resolution.  
* **Response Structure**: Reference relevant **Maple modules** (Checklist, Knowledge Base, Survey, Ticket) where applicable.

---

## 

## 

**Checklist Assessment Sections and Questions:**  
"The system evaluates stores based on various **assessment sections**, each containing **specific evaluation questions**. These questions use different response types such as **Binary (Yes/No)**, **Trinary (Yes/No/NA)**, or **Multiple Choice (e.g., Good/Average/Bad)**. Below is the structured breakdown of the checklist questions:

### **Customer Satisfaction**

* **How would you rate your overall customer satisfaction in this store?** *(Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*  
* **Is Our Team Staff Helpful Towards Our Customers?** *(Trinary: Yes/No/NA)*  
* ***Do Our Product And Services Meet Client Expectations?** (Binary: Yes/No)*  
* ***How Would You Rate The Overall Atmosphere Of Our Store?** (Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*  
* ***Has The Store Met The Customer Experience We Intent To Achieve In Vineyard Valley?** (Multiple Choice: Not Achieved/ Achieved)*


### **Store Evaluation**

* **How Satisfied Are You With The Overall Reported Store KPI's?** *(Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*  
* **How Satisfied Are You With The Reported Average Daily Store Traffic?** *(Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*  
* ***How Satisfied Are You With The Reported  Average Transaction Rate?** (Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*  
* ***How Satisfied Are You With The Reported Shrinkage Rates (Inventory Loss)?** (Multiple Choice: Very Satisfied/Satisfied/Somewhat Satisfied/Not Satisfied)*


### **Visual Merchandising**

* **Are Window Displays Attractive, Up-To-Date, And Aligned With Current Promotions?** *(Binary: Yes/No)*  
* **Is The Store Clean, Organized, And Free Of Clutter? Is The Overall Atmosphere Pleasant For Customers?** *(Binary: Yes/No)*  
* **Are Products Displayed Neatly, Facing Forward, And Following Planograms?** *(Binary: Yes/No)*  
* ***Is There A Focus On Highlighting Key Products Or New Arrivals Throughout The Store?** (Binary: Yes/No)*

### **Stock Management**

* **Are Expired Testers And Products Being  Removed Daily?**  *(Binary: Yes/No)*  
* **Is The Stock Organized Efficiently For Easy Picking And Restocking?** *(Binary: Yes/No)*  
* ***Are There Any Reported Inefficiencies In The Delivering Process?** (Binary: Yes/No)*

### **Team Management**

* **Are There Enough Employees On The Sales Floor To Adequately Assist Customers, And Has A Clear Floor Distribution Plan Been Communicated For Today's Needs?** *(Binary: Yes/No)*  
* **Are All Employees Wearing The Required Uniform Elements (Shirt, Pants, Shoes, Name Tag, Etc)** *(Binary: Yes/No)*  
* ***Are Brand Representatives Installed And Equipped For The Day?** (Binary: Yes/No)*  
* ***Is The Store Manager Well Informed On All Internal Affairs And Up To Date On Daily Sales Reports?** (Binary: Yes/No)*

## **Example Prompts & Expected Answers (Revised):**

| PROMPT | ANSWER |
| :---- | :---- |
| Identify problematic topics for stores in France. | Cleaning is the most recurring issue. 20% of stores have reported this consistently over the last 3 months.Source: (Checklist data) |
| Compare France's performance to last month. | France's overall performance is 84%, up from last month. Bretagne improved (+4.3%), Ile-de-France declined (-1.6%). Source: (Checklist data) |
| Which areas require monitoring? | Most regions are performing well. Bretagne requires attention due to challenges with employee management and visual merchandising. Source: (Checklist, Survey data) |
| Compare Flagship vs. Concept Store performance. | Flagship Stores: 83.3%. Concept Stores: 81.8%. Both declined slightly. Source: (Checklist data) |
| Explain the 1.6% October performance drop. | The October drop likely resulted from incomplete checklist submissions and Employee Management issues in Ile-de-France and Normandie. Source: (Checklist data) |
| Summarize 2024 monthly performance trends. | Consistent improvement throughout 2024, with increases in February (+69.4%) and July (+6.2%). October saw a 1.6% decline. Source: (Checklist data) |
| Identify top improvement months and contributing factors. | February (+69.4%) and July (+6.2%) had the highest gains, driven by checklist completion and staff training. Source: (Checklist data) |
| Identify areas struggling with Employee Management. | Bretagne (73.6%) and Normandie (74.6%) have the lowest Employee Management scores. Source: (Checklist data) |
| Rank topics by completion rate. | Store Evaluation (85.6%) Visual Merchandising (74.8%) Stock Management (74.6%) Employee Management (73.6%) Source: (Checklist data)  |
| Identify incomplete checklists (Bretagne). | Bretagne has incomplete Employee Management and Stock Management checklists. Source: (Checklist data) |
| Animation checklist completion rate. | 100% of stores have completed the Animation checklist. Source: (Checklist data) |
| Identify stores with consistently delayed checklists. | Normandie has consistently delayed submissions. Source: (Checklist data) |
| Identify top-performing clusters (this quarter). | Corner Stores (82.6%) performed best. Flagship (83.3%) and Concept (81.8%) Stores are slightly lower. Source: (Checklist data) |
| Compare Corner vs. Flagship Store performance. | Corner Stores (82.6%) are slightly behind Flagship Stores (83.3%). Both need improvement in Employee Management. Source: (Checklist data) |
| Identify recurring problems in Ile-de-France. | Employee Management and Stock Management are recurring issues in Ile-de-France. Source: (Checklist data) |
| Identify regions with improved cleaning issue handling. | Grand Est has shown the highest improvement in resolving cleaning issues. Source: (Checklist data) |
| Identify regions with persistent Stock Management issues. | Normandie and Bretagne have persistent Stock Management issues (below the 74.6% national average). Source: (Checklist data) |
| Explain the 0% checklist usage rate. | The 0% usage rate may indicate data entry issues or lack of user engagement. Further investigation is required. Source: (Checklist data) |
| Suggest actions to improve checklist usage. | Engage regional managers, emphasize checklist importance, provide training, and conduct regular follow-ups. Source: (Checklist data) |
| Identify stores needing follow-up (low engagement). | Normandie and Bretagne stores show the lowest engagement (0% checklist usage). Source: (Checklist data) |

### **Final Reinforcements:**

* ✅ **Responses are exclusively for Maple users.**  
* ✅ **Language adaptation per user input (TR, EN, FR, DE, NL).**  
* ✅ **Data access aligned with user permissions.**  
* ✅ **References to Maple’s modules (Checklist, Survey, etc.) included in answers.**
`,
};

const SpeechToText = ({ onChange, onSend }) => {
  const [transcript, setTranscript] = useState(''); // Kaydedilen metni tutacak state
  const [isListening, setIsListening] = useState(false); // Dinleme durumunu tutacak state

  // SpeechRecognition API'sini başlat
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  // Dil ayarı (örneğin Türkçe)
  // recognition.lang = "tr-TR";
  // united state
  recognition.lang = 'en-US';

  // Sürekli dinleme için ayar
  recognition.continuous = false;

  // Konuşma başladığında çalışacak olay
  recognition.onstart = () => {
    setIsListening(true);
  };

  // Konuşma bittiğinde çalışacak olay
  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setTranscript(transcript); // State'i güncelle
    onChange(transcript); // Dışarıya seçilen metni gönder
    setIsListening(false);
  };

  // Hata durumunda çalışacak olay
  recognition.onerror = (event) => {
    console.error('Hata:', event.error);
    setIsListening(false);
  };

  // Konuşma sona erdiğinde çalışacak olay
  recognition.onend = () => {
    setIsListening(false);
    if (transcript) {
      onSend();
    }
  };

  // Butona tıklandığında kayıt başlat/durdur
  const handleListen = () => {
    if (isListening) {
      recognition.stop(); // Dinlemeyi durdur
    } else {
      recognition.start(); // Dinlemeyi başlat
    }
  };

  return (
    <IconButton
      color={isListening ? 'secondary' : 'primary'}
      onClick={handleListen}
      aria-label="Microphone"
    >
      <MicRounded />
    </IconButton>
  );
};
const MapleAIAsistant = ({ disablePadding, mode = 'page' }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const [isWriting, setIsWriting] = useState(false);
  const writerRef = React.useRef('');
  const [count, setCount] = useState(0);
  const [extendPaperSize, setExtendPaperSize] = useState(false);
  const scrollerRef = React.useRef(null);
  const userState = useUserController((f) => f.user);
  const avatarSrc = documentPath(userState?.picture);

  const customStyle =
    mode === 'page'
      ? {}
      : {
          maxWidth: extendPaperSize ? 600 : 600,
          height: extendPaperSize ? 800 : 800,
        };
  useLayoutEffect(() => {
    // Eğer history varsa yükle
    const history = localStorage.getItem('mapleAIHistory');
    if (history) {
      setMessages(JSON.parse(history));
    }
    // Sistem prompt'unu gönder
    openai.chat.completions
      .create({
        model: MODEL_NAME,
        messages: [systemPrompt],
        max_tokens: 32768,
        frequency_penalty: 0.0,
        temperature: 0.95,
        // temperature: 0.8,
        stream: true,
        // parallel_tool_calls: 4,
      })
      .then((response) => {
        let resp = response.choices?.[0]?.delta?.content || '';
        if (!!resp) {
          setMessages((prev) => [
            ...prev,
            {
              content: resp,
              role: 'system',
            },
          ]);
        }
      });
  }, []);
  // save history
  useEffect(() => {
    localStorage.setItem('mapleAIHistory', JSON.stringify(messages));
  }, [messages]);
  const scrollToBottom = () => {
    if (scrollerRef.current) {
      // smoth scroll
      scrollerRef.current.scrollTo({
        top: scrollerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };
  const handleSend = async () => {
    if (!input.trim()) return;

    // Kullanıcı mesajını ekle
    setMessages((prev) => [
      ...prev,
      { content: input, role: 'user', time: new Date().toLocaleTimeString() },
      {
        content: '',
        role: 'asistant',
        isWriting: true,
        time: new Date().toLocaleTimeString(),
      },
    ]);
    scrollToBottom();
    setInput('');
    // curl "https://xigf46chs76v8imr.us-east-1.aws.endpoints.huggingface.cloud/v1/chat/completions" \

    // Hugging Face API'sine istek gönder
    try {
      setIsWriting(true);
      let previouseMessage = messages.map((f) => ({
        role: f.role,
        content: f.content,
      }));
      const stream = await openai.chat.completions.create({
        model: MODEL_NAME,
        messages: [
          systemPrompt,
          ...previouseMessage,
          {
            role: 'user',
            content: input,
          },
        ],
        max_tokens: 8192,
        stream: true,
      });

      let tmpMessages = '';
      for await (const chunk of stream) {
        // process.stdout.write(chunk.choices[0]?.delta?.content || '');
        // update last sender!=user message with the model response
        if (!chunk.choices?.[0]?.delta?.content) continue;
        tmpMessages += chunk.choices?.[0]?.delta?.content;
        // console.log('chunk', chunk.choices?.[0]?.delta?.content, tmpMessages);
        writerRef.current = tmpMessages;
        // console.log('writerRef.current', writerRef.current);
        setCount((f) => f + 1);
        scrollToBottom();
      }
      // set isWriting to false
      setMessages((prev) => {
        const lastUserMessageIndex = prev
          .map((msg, index) => (msg.role === 'asistant' ? index : null))
          .filter((index) => index !== null)
          .pop();
        if (lastUserMessageIndex !== undefined) {
          prev[lastUserMessageIndex].isWriting = false;
          prev[lastUserMessageIndex].content = tmpMessages;
        }
        return [...prev];
      });
      writerRef.current = '';
      setIsWriting(false);
      scrollToBottom();
      // setMessages((prev) => [...prev, { text: botMessage, sender: 'bot' }]);
    } catch (error) {
      setIsWriting(false);
      console.error('API isteği başarısız oldu:', error);
    }

    // Input'u temizle
    setInput('');
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        width: '100%',
        height: '100%',
        padding: disablePadding ? 0 : 8,
        // border: '1px solid #ccc',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#f9f9f9',
        ...(customStyle || {}),
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          style={{ height: 56 }}
        >
          <Toolbar>
            <ListItemAvatar>
              <Avatar
                src={
                  'https://alpha.workmaple.com/app-icons/android-icon-144x144.png'
                }
                sx={{ width: 32, padding: '4px' }}
              />
            </ListItemAvatar>{' '}
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Maple AI
            </Typography>
            {/* Extend */}
            {/* {mode === 'fab' && (
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setExtendPaperSize((f) => !f)}
              >
                {extendPaperSize ? <ZoomInMap /> : <ZoomOutMap />}
              </IconButton>
            )} */}
            <IconButton
              edge="end"
              color="inherit"
              disabled={messages.length === 0}
              onClick={() => setMessages([])}
            >
              <ClearOutlined />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Box
          sx={{
            height: 'calc(100% - 256px)',
            flexGrow: 1,
            overflow: 'overlay',
          }}
          ref={scrollerRef}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '10px',
              overflowY: 'auto',
              flexShrink: 1,
              // toolbar ile prompt input alanı arasındaki boşluğu ayarlar
            }}
          >
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent:
                    msg.role === 'user' ? 'flex-end' : 'flex-start',
                  marginBottom: '10px',
                  minWidth: 240,
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={
                      msg.role === 'user'
                        ? avatarSrc
                        : 'https://alpha.workmaple.com/app-icons/android-icon-72x72.png'
                    }
                    sx={{ width: 32, padding: '4px' }}
                  />
                </ListItemAvatar>
                <Box
                  sx={{
                    backgroundColor:
                      msg.role === 'user' ? '#d1e7dd' : '#f8d7da',
                    borderRadius: '8px',
                    maxWidth: '70%',
                    px: '16px',
                    py: '8px',
                  }}
                >
                  <Typography variant="body1">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {msg.isWriting ? writerRef.current : msg.content}
                    </ReactMarkdown>
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems={'center'}
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={() => {
                        navigator.clipboard.writeText(msg.content);
                      }}
                    >
                      <CopyOutlined size={16} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="caption" sx={{ color: '#666' }}>
                      {msg.time}{' '}
                      {msg.isWriting && <CircleProggressBar size={16} />}
                    </Typography>
                  </Stack>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            border: `1px solid ${
              theme.palette.mode === 'dark' ? '#404040' : '#d4d4d4'
            }`,
            borderRadius: '8px',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#171717' : '#fafafa',
            color: theme.palette.mode === 'dark' ? '#d4d4d4' : '#525252',
            maxHeight: '256px',
          }}
        >
          <Box sx={{ p: 2 }}>
            <TextField
              multiline
              minRows={2}
              maxRows={10}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Type "Translate the following English text to French: "Hello, how are you?"'
              variant="standard"
              fullWidth
              // eğer kullanıcı enter tuşuna basarsa mesajı gönder
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.persist();
                  e.preventDefault();
                  e.stopPropagation();
                  handleSend();
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: 'transparent',
                  // color: theme.palette.mode === 'dark' ? '#d4d4d4' : '#525252',
                  padding: '8px',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              px: 2.5,
              py: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Camera */}
              <IconButton
                title="Use Camera"
                aria-label="Use Camera"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <CameraEnhanceRounded />
              </IconButton>
              {/* Upload Image */}
              <IconButton
                title="Upload Image"
                aria-label="Upload Image"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <UploadFileRounded />
              </IconButton>
              {/* Use Voice */}
              {/* <SpeechToText onChange={(text) => setInput(text)} onSend={()=>{
                handleSend();
              }} /> */}
              <IconButton
                title="Use Voice"
                aria-label="Use Voice"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <MicRounded />
              </IconButton>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSend}
              disabled={isWriting || !input.trim()}
              startIcon={<AutoAwesome />}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* <Box sx={{ display: 'flex' }}>
        
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Mesajınızı yazın..."
          sx={{ marginRight: '10px' }}
          
        />
        <Button variant="contained" color="primary" onClick={handleSend}>
          Gönder
        </Button>
      </Box> */}
    </Container>
  );
};
export const MapleAIAsistantFab = () => {
  const [anchorEl, setAnchorEl] = useState(null); // Modal açık/kapalı durumu
  const location = useLocation();
  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  console.log(
    "localStorage.getItem('mapleAiIsEnable')",
    localStorage.getItem('mapleAiIsEnable'),
  );
  return;

  if (localStorage.getItem('mapleAiIsEnable') !== 'true') return null;
  // eğer urlde maple-ai yazıyorsa render alma
  if (location.pathname.includes('maple-ai')) return null;
  return (
    <>
      {/* FAB Butonu */}
      <Fab
        color="primary"
        aria-label="chat"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleOpen}
      >
        <AutoAwesome />{' '}
        <AutoAwesome
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            width: 12,
            height: 12,
            zIndex: 1000,
          }}
        />
      </Fab>

      {/* Modal (Popup) */}
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MapleAIAsistant disablePadding mode="fab" />
      </Popover>
    </>
  );
};

export default MapleAIAsistant;