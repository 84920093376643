import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import AnimeteurHome from '@maple-tech/animeteur-web-app/HomePage';

import ApartmentIcon from '@mui/icons-material/Apartment';
import { GroupRounded } from '@mui/icons-material';

export const Animeteur_BASE_ROUTE = {
  index: '/animeteur',
};

const ROUTES = {
  index: 'animeteur',
};

const subRoutesList = (user) => [
  {
    menuItem: false,
    requiredRoles: [
      'admin',
      (user.id === 'B2T7XZYENNGNYXJMFHUCLFIHRA' ||
        user.id === 'CRZZ3M25KJAZO2EQL2A2MOBY7Y' ||
        user.id === 'SXMETXVFUJDKRNBNYBRQ33CORA') &&
        COMPANIES.SEPHORA_FRANCE,
    ].filter(Boolean),
    path: '',
    element: (
      <Page
        title="retail:navigation.animateurs.section-title"
        contentStyle={{ padding: 0 }}
      >
        <AnimeteurHome />
      </Page>
    ),
    index: true,
  },
];

const AnimeteurPagesRoutes = (user) => ({
  menuItem: true,
  requiredRoles: [
    'admin',
    (user.id === 'B2T7XZYENNGNYXJMFHUCLFIHRA' ||
      user.id === 'CRZZ3M25KJAZO2EQL2A2MOBY7Y' ||
      user.id === 'SXMETXVFUJDKRNBNYBRQ33CORA') &&
      COMPANIES.SEPHORA_FRANCE,
  ].filter(Boolean),
  path: ROUTES.index,
  section: true,
  order: 7,
  title: 'retail:navigation.animateurs.section-title',
  icon: <GroupRounded />,
  element: <Outlet />,
  children: subRoutesList(user),
});

export default AnimeteurPagesRoutes;
