import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import FloorManagementHome from '@maple-tech/floor-management-web-app/HomePage';
import AnimeteurHome from '@maple-tech/animeteur-web-app/HomePage';

import ApartmentIcon from '@mui/icons-material/Apartment';

export const FloorManagement_BASE_ROUTE = {
  index: '/floor-management',
  animeteur: 'animetaur',
};

const ROUTES = {
  index: 'floor-management',
};

const subRoutesList = (user) => {
  if (user.companyId === COMPANIES.SEPHORA_FRANCE) {
    return [
      {
        menuItem: false,
        requiredRoles: ['admin'].filter(Boolean),
        title: 'retail:navigation.floor-management.internal-employees',
        path: '',
        element: (
          <Page
            title="retail:navigation.floor-management.section-title"
            contentStyle={{ padding: 0 }}
          >
            <FloorManagementHome />
          </Page>
        ),
        index: true,
      },
    ];
  }
  return [
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
      ],
      title: 'retail:navigation.floor-management.internal-employees',
      path: '',
      element: (
        <Page
          title="retail:navigation.floor-management.section-title"
          contentStyle={{ padding: 0 }}
        >
          <FloorManagementHome />
        </Page>
      ),
      index: true,
    },
    {
      menuItem: true,
      requiredRoles: [
        'admin',
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
      ],
      title: 'retail:navigation.floor-management.external-employees',
      path: FloorManagement_BASE_ROUTE.animeteur,
      element: (
        <Page
          title="retail:navigation.animateurs.section-title"
          contentStyle={{ padding: 0 }}
        >
          <AnimeteurHome />
        </Page>
      ),
    },
  ];
};
const FloorManagementPagesRoutes = (user) => ({
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.DEMO,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
  ].filter(Boolean),
  path: ROUTES.index,
  section: true,
  order: 7,
  title: 'retail:navigation.floor-management.section-title',
  icon: <ApartmentIcon />,
  element: <Outlet />,
  children: subRoutesList(user),
});

export default FloorManagementPagesRoutes;
