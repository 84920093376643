import { Outlet } from 'react-router-dom';

import { DiffOutlined, SnippetsOutlined } from '@ant-design/icons';
import SurveyCreation from '@maple-tech/survey-web-app/Create'; // exports kısmı düzeltilmeli
import SurveyForm from '@maple-tech/survey-web-app/Form'; // exports kısmı düzeltilmeli
import Survey from '@maple-tech/survey-web-app/List'; // exports kısmı düzeltilmeli
import SurvayDetail from '@maple-tech/survey-web-app/Metrics'; // exports kısmı düzeltilmeli
import SurvayTemplates from '@maple-tech/survey-web-app/TemplateList'; // exports kısmı düzeltilmeli

import PollSharp from '@mui/icons-material/PollSharp';

import Page from '../../../core/components/page';
import { COMPANIES, COUNTRIES } from 'Core/API';

export const SURVEY_ROUTES = {
  index: 'survey',
  create: 'template-edit/:surveyId',
  templates: 'templates',
  form: 'form/:surveyId',
  metrics: 'metrics/:surveyId',
};

const subRoutesList = (authorizationLevel, countryId) => [
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
    ].filter(Boolean),
    path: '',
    title: 'retail:navigation.survey.list',
    element: (
      <Page
        title="retail:navigation.survey.surveys"
        contentStyle={{ padding: 0 }}
      >
        <Survey />
      </Page>
    ),
    index: true,
  },
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
    ].filter(Boolean),
    path: SURVEY_ROUTES.templates,
    title: 'retail:navigation.survey.templates',
    element: (
      <Page
        title="retail:navigation.survey.templates"
        contentStyle={{ padding: 0 }}
      >
        <SurvayTemplates />
      </Page>
    ),
    // element: <SurvayTemplates />,
    index: true,
  },
  {
    menuItem: false,
    path: SURVEY_ROUTES.metrics,
    element: (
      <Page
        title="retail:navigation.survey.dashboard"
        contentStyle={{ padding: 0 }}
      >
        <SurvayDetail />
      </Page>
    ),
    // index: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
    ].filter(Boolean),
  },
  {
    menuItem: false,
    path: SURVEY_ROUTES.create,
    element: (
      <Page
        title="retail:navigation.survey.create"
        contentStyle={{ padding: 0 }}
      >
        <SurveyCreation />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
    ].filter(Boolean),
  },
  {
    menuItem: false,
    path: SURVEY_ROUTES.form,
    element: (
      <Page
        title="retail:navigation.survey.section-title"
        contentStyle={{ padding: 0 }}
      >
        <SurveyForm />
      </Page>
    ),
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
      authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
    ].filter(Boolean),
  },
];

/* 
Template for sidebar links
{
      isComingSoon: false,
      path: "/settings",
      icon: <SettingOutlined />,
      menuHidden: true,
}
*/
export const SurveySidebarLinks = {
  title: 'Survey',
  path: SURVEY_ROUTES.index,
  icon: <SnippetsOutlined />,
  routes: [
    {
      title: 'Survey Templates',
      path: SURVEY_ROUTES.templates,
      icon: <DiffOutlined />,
    },
  ],
};

const SurveyPagesRoutes = (authorizationLevel, countryId) => ({
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.DEMO,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
    // COUNTRIES.MM_HOLLAND === countryId && COMPANIES.MEDIAMARKT,
    authorizationLevel.DISTRICT_MANAGER && COMPANIES.SEPHORA,
  ].filter(Boolean),
  section: true,
  order: 5,
  title: 'retail:navigation.survey.section-title',
  path: SURVEY_ROUTES.index,
  icon: <PollSharp />,
  element: <Outlet />,
  children: subRoutesList(authorizationLevel, countryId),
});

export default SurveyPagesRoutes;
