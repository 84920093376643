// @ts-nocheck
import { getAuthKey, getInstance, getXCompanyID } from '@maple-tech/auth';
import dayjs from 'dayjs';

const DOCUMENTS_API = getInstance('DocumentsAPIV2');

export const documentPath = (documentId) => {
  const params = `forceReadBytes=true&Authorization=${getAuthKey()}&X-Company=${getXCompanyID()}`;
  return `${DOCUMENTS_API.defaults.baseURL}/documents/${documentId}?${params}`;
};

export const uploadFunction = async (file, config) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      sharingMode: 'public',
    }),
  );
  formData.append('document', file);
  if (!config) config = {};
  config.headers = {
    'Content-Type': 'multipart/form-data',
  };
  return DOCUMENTS_API.post('/documents', formData, config);
};
export const uploadFiles = (files) =>
  Promise.all(files.map((f) => uploadFunction(f)));

function getAuthorizationHeaders() {
  throw new Error('Function not implemented.');
}

export function fCurrency(number) {
  if (!number) return '';

  number = parseFloat(number).toFixed(2);
  // number = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'TRY',
  // }).format(number);

  return number;
}

export function fDate(date, newFormat) {
  const fm = newFormat || 'LL';

  return date ? dayjs(date).format(fm) : '';
}
export function fDateTime(date, newFormat) {
  const fm = newFormat || 'LLL';

  return date ? dayjs(date).format(fm) : '';
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}
