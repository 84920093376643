import React from 'react';
import { useTranslation } from 'react-i18next';

import { BackTop } from 'antd';

import Header from '../page-header';
import Sidebar from '../page-menu';

import './page.less';
const Page = ({
  className,
  title,
  headerContent,
  showBack = true,
  backTo,
  breadCrumbs,
  contentClassName,
  children,
  subHeader = <></>,
  contentStyle,
}) => {
  const { t } = useTranslation();
  return (
    <div id="page-wrapper" className={className}>
      <Header
        title={typeof title === "string"? t(title):title}
        content={headerContent}
        showBack={showBack}
        backTo={backTo}
        breadCrumbs={breadCrumbs}
      />
      {subHeader}
      <div id="page-content" className={contentClassName} style={contentStyle}>
        {children}
        <BackTop />
      </div>
    </div>
  );
};
export default Page;
